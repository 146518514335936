

























































































































































































































































































































































































import { computed, defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { formatDistanceToNow } from "date-fns";
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from "pinia";
import { mapActions } from "vuex";
import NotificationBanner from "@/components/elements/NotificationBanner.vue";
import { clubApi } from "@/lib/backend";
import type { IomodelsFitnessAfspraakExtern } from "@/lib/backend/reserveer.api";
import { APPOINTMENT_MAP } from "@/lib/constants/options";
import { formatErrorMessage } from "@/lib/formatErrorMessage";
import { useCurrentGym } from "@/lib/hooks/useCurrentGym";
import { queries } from "@/lib/query/queries";
import { toast } from "@/lib/vue2-sonner";
import { logger } from "@/logger";
import { useAuthStore } from "@/pinia/auth";
import { useCoachStore } from "@/pinia/coach";
import { getCoachImage, getIconName, getMemberImage } from "@/utils/images";

export default defineComponent({
	components: { NotificationBanner },
	setup() {
		const { id: currentGymId } = useCurrentGym();

		const { data: coaches } = useQuery({
			...queries.coach.all({ gymId: currentGymId.value }),
			queryKey: computed(() => queries.coach.all({ gymId: currentGymId.value }).queryKey),
		});

		return { currentGymId, coaches };
	},
	data: () => ({
		profile: {
			backgroundImage:
				"https://profitgym.nl/content/uploads/2021/03/10-Persoonlijke-Begeleiding-ProFit-Gym-Sportschool-1000x667.jpg",
			quote: "Wij zorgen dat iedereen blijft sporten",
		},
		showAllAppointments: false,
	}),
	computed: {
		...mapPiniaState(useAuthStore, ["user", "isClubAanvoerder"]),
		...mapPiniaState(useCoachStore, {
			userId: "id",
			userName: "name",
			userRole: "role",
			membersPresentWithoutCoach: "membersPresentWithoutCoach",
			myMembersPresent: "myMembersPresent",
			myMembersAbsentLong: "myMembersAbsentLong",
			appointments: ({ appointments }) =>
				appointments.filter(
					(appointment) => appointment.aanwezigheid && appointment.ingediend === false,
				),
			stats: ({ statistics }) => {
				let percentage =
					statistics.personal.aantalLedenUit30GroepGeholpen >
					statistics.personal.aantalLedenUit30GroepGeweest
						? 100
						: Math.floor(
								(statistics.personal.aantalLedenUit30GroepGeholpen /
									statistics.personal.aantalLedenUit30GroepGeweest) *
									100,
							);
				if (statistics.personal.aantalLedenUit30GroepGeholpen === 0) {
					percentage = 0;
				}
				return [
					{
						type: "big-percentage",
						label: "Geholpen",
						badge: `${statistics.personal.aantalLedenUit30GroepGeholpen}/${
							statistics.personal.aantalLedenUit30GroepGeholpen >
							statistics.personal.aantalLedenUit30GroepGeweest
								? statistics.personal.aantalLedenUit30GroepGeholpen
								: statistics.personal.aantalLedenUit30GroepGeweest
						}`,
						value: isNaN(percentage) || percentage === Infinity ? 0 : percentage,
						confetti: percentage === 100,
					},
					{
						type: "row-group",
						label: "Algemeen",
						rows: [
							{
								label: "30% sporters",
								value: statistics.general.aantal30ProcentSporters,
							},
							{
								label: "70% sporters",
								value: statistics.general.aantal70ProcentSporters,
							},
						],
					},
					{
						type: "row",
						label: "Totaal aantal leden",
						value: statistics.general.totaalAantalGekoppeldeSporters,
					},
				];
			},
			toContact: ({ myMembersAbsentLong }) =>
				myMembersAbsentLong.sort(
					(a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
				),
			toHelp: ({ myMembersPresent, membersPresentWithoutCoach }) =>
				[...myMembersPresent, ...membersPresentWithoutCoach].sort(
					(a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
				),
		}),
	},
	async created() {
		await this.check();
	},
	methods: {
		...mapPiniaActions(useAuthStore, { initAuth: "init", getInfo: "getInfo" }),
		...mapPiniaActions(useCoachStore, { getCoach: "get" }),
		formatDistanceToNow,
		...mapActions("modal", {
			openModal: "openModal",
		}),
		shouldShowNotificationBanner() {
			if (this.membersPresentWithoutCoach.length < 1) return false;

			// Always show for club aanvoerder, only show for coaches if its their location.
			return this.isClubAanvoerder || this.isMyVestiging();
		},
		currentUserWithVestigingenIds() {
			return this.coaches?.data.find((coach) => coach.id === this.userId);
		},
		isMyVestiging() {
			const coachVestigingen = this.currentUserWithVestigingenIds()?.gymIds;
			return coachVestigingen?.includes(this.currentGymId) ?? false;
		},
		hasNoCoach(user: unknown) {
			return (user as { message: string })?.message === "heeft geen coach";
		},
		async openAssignUsersModal(event: MouseEvent, memberId: number) {
			event.preventDefault();
			if (!this.userId) return logger.error("No user id found");
			const callback = async () => await this.getCoach();

			this.openModal({
				name: "assign-members",
				data: { memberIds: [memberId] },
				callback,
			});
		},
		async assignUser(event: MouseEvent, user: TODO) {
			event.preventDefault();

			this.openModal({
				name: "confirm",
				data: {
					amount: 1,
					message: `Weet je zeker dat je ${user.voornaam ?? "dit lid"} aan jezelf wilt toewijzen?`,
				},
				callback: async () => {
					if (!this.userId) return;

					await toast
						.promise(
							clubApi.coach.ledenToewijzen(this.userId, {
								ledenIds: [user.id],
							}),
							{
								loading: "Lid toewijzen...",
								success: () => {
									this.getCoach();
									return "Lid toegewezen";
								},
								error: formatErrorMessage,
							},
						)
						?.unwrap();
				},
			});
		},
		async check() {
			await this.initAuth();
			await this.getInfo();
			await this.getCoach();
			await this.checkConfetti();
		},
		async checkConfetti(single = false) {
			if (this.stats.some((object) => object.confetti)) {
				const { default: confetti } = await import("canvas-confetti");
				const fireConfetti = () => {
					confetti({
						angle: 120,
						origin: {
							x: 1,
							y: 1,
						},
					});
				};
				if (single) {
					fireConfetti();
				} else {
					[
						0,
						Math.floor(Math.random() * 500) + 1000,
						Math.floor(Math.random() * 500) + 2500,
					].forEach((value) => {
						setTimeout(fireConfetti, value);
					});
				}
			}
		},
		getAppointmentData({
			aanwezigheid,
		}: {
			aanwezigheid?:
				| Omit<IomodelsFitnessAfspraakExtern["aanwezigheid"], "isTemporaryMember">
				| null
				| undefined;
		}) {
			if (!aanwezigheid) {
				return [];
			}

			return Object.entries(aanwezigheid)
				.filter(([key]) => Object.keys(APPOINTMENT_MAP).includes(key))
				.filter(([, value]) => value === true)
				.map(([key]) => ({
					key,
					...APPOINTMENT_MAP[key],
				}));
		},
		getCoachImage,
		getMemberImage,
		getIconName,
	},
});
